import React from "react"

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 5.2916666 5.2916667"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layer1">
      <g id="Layer_1-2" data-name="Layer 1" transform="scale(0.00585361,0.00586659)">
        <path
          className="cls-1"
          d="M 452,901.91 Q 228.29,901.91 4.59,902 C 0.84,902 0,901.16 0,897.41 Q 0.15,451 0,4.59 C 0,0.84 0.84,0 4.59,0 Q 452,0.15 899.41,0 c 3.75,0 4.59,0.84 4.59,4.59 q -0.15,446.42 0,892.82 c 0,3.75 -0.84,4.59 -4.59,4.59 Q 675.71,901.84 452,901.91 Z M 358.45,784.13 a 31.94,31.94 0 0 0 13.16,-2.32 c 14.23,-6 25.93,-15.55 33.86,-28.86 8.53,-14.26 18.53,-27.45 27.63,-41.27 10.28,-15.61 21.1,-30.91 31.54,-46.41 9.61,-14.27 19.66,-28.27 28.9,-42.82 11.88,-18.73 24.81,-36.79 37.27,-55.15 12.46,-18.36 25,-37 37.57,-55.41 6.54,-9.57 14,-18.61 19.77,-28.6 8,-13.81 18.15,-26.1 26.71,-39.51 9.44,-14.77 20,-28.92 29.53,-43.61 10,-15.4 21,-30.11 30.86,-45.61 10.21,-16 21.62,-31.39 32.11,-47.26 9.22,-13.94 19.12,-27.41 28,-41.6 11.32,-18.1 23.67,-35.55 35.88,-53.08 8.9,-12.78 6.77,-29.73 -1.62,-41.85 -4.46,-6.45 -11.11,-9.87 -17.13,-14.13 -14.2,-10 -28.64,-19.74 -43,-29.53 -7,-4.76 -13.68,-10.85 -21.76,-12.79 -17.43,-4.2 -30,-1 -41.94,15.41 -16.79,23.05 -31.86,47.27 -48.52,70.39 -9.27,12.87 -17.45,26.52 -26.4,39.63 -12,17.62 -24.31,35.08 -36.14,52.81 -8.53,12.81 -17.05,25.66 -25.72,38.37 -10.83,15.87 -21.55,31.82 -32.55,47.58 -11.24,16.12 -22.4,32.36 -32.92,49 -11.26,17.79 -24.12,34.48 -35.38,52.29 -9.28,14.67 -20.32,28.22 -29.46,43 -8.31,13.39 -18.24,25.63 -26.3,39.17 a 96,96 0 0 1 -11.28,15.71 c -1.46,1.59 -2.18,1.51 -3.51,0.2 -8.25,-8.15 -16.59,-16.23 -24.8,-24.43 -8,-8 -15.83,-16.09 -23.78,-24.1 q -10.5,-10.57 -21.08,-21.07 L 244.68,485 c -7.5,-7.49 -14.81,-15.18 -22.53,-22.44 -9.06,-8.53 -16.81,-18.42 -26.8,-25.94 C 179.94,425 162.43,426.1 148,437.91 c -10.62,8.69 -19.81,18.9 -29.46,28.59 -9.65,9.69 -19.7,19.07 -28.71,29.5 a 32.92,32.92 0 0 0 -6.57,11.51 c -4.73,15 -1.24,28.43 9.88,39.56 Q 199,653 304.89,758.9 c 8,8.05 16.67,14.93 27.21,19.55 a 64.25,64.25 0 0 0 26.35,5.68 z"
          id="path13"
        />

        <path
          className="cls-2"
          d="m 358.45,786.13 a 64.25,64.25 0 0 1 -26.35,-5.68 c -10.54,-4.62 -19.17,-11.5 -27.21,-19.55 Q 199.1,654.88 93.14,549 C 82,537.9 78.53,524.48 83.26,509.47 A 32.92,32.92 0 0 1 89.83,498 c 9,-10.39 19,-19.75 28.72,-29.46 9.72,-9.71 18.84,-19.9 29.46,-28.59 14.42,-11.81 31.93,-12.88 47.34,-1.29 10,7.52 17.74,17.41 26.8,25.94 7.72,7.26 15,14.95 22.53,22.44 L 268,510.23 q 10.5,10.53 21,21.07 c 8,8 15.79,16.12 23.78,24.1 8.21,8.2 16.55,16.28 24.8,24.43 1.33,1.31 2.05,1.39 3.51,-0.2 a 96,96 0 0 0 11.28,-15.71 c 8.06,-13.54 18,-25.78 26.3,-39.17 9.14,-14.74 20.18,-28.29 29.46,-43 11.26,-17.81 24.12,-34.5 35.38,-52.29 10.52,-16.63 21.68,-32.87 32.92,-49 11,-15.76 21.72,-31.71 32.55,-47.58 8.67,-12.71 17.19,-25.56 25.72,-38.37 11.83,-17.73 24.11,-35.19 36.14,-52.81 9,-13.11 17.13,-26.76 26.4,-39.63 16.66,-23.14 31.74,-47.34 48.5,-70.39 11.9,-16.37 24.51,-19.61 41.94,-15.41 8.08,1.94 14.77,8 21.76,12.79 14.38,9.79 28.82,19.49 43,29.53 6,4.26 12.67,7.68 17.13,14.13 8.39,12.12 10.52,29.07 1.62,41.85 -12.21,17.53 -24.56,35 -35.88,53.08 -8.88,14.19 -18.78,27.66 -28,41.6 -10.49,15.87 -21.9,31.22 -32.11,47.26 -9.87,15.5 -20.86,30.21 -30.86,45.61 -9.53,14.69 -20.09,28.84 -29.53,43.61 -8.56,13.41 -18.7,25.7 -26.71,39.51 -5.8,10 -13.23,19 -19.77,28.6 -12.58,18.43 -25,36.94 -37.57,55.41 -12.57,18.47 -25.39,36.42 -37.27,55.15 -9.19,14.6 -19.24,28.6 -28.85,42.87 -10.44,15.5 -21.26,30.8 -31.54,46.41 -9.1,13.82 -19.1,27.01 -27.63,41.32 -7.93,13.31 -19.63,22.82 -33.86,28.86 a 31.94,31.94 0 0 1 -13.16,2.27 z"
          id="path15"
          fill="#ddd7e4"
        />
      </g>
    </g>
  </svg>
)
