import React from "react"
import "./index.scss"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import IndividualFilterAccordion from "@components/FilterGroup/IndividualFilterAccordion"
import { BlogCategory, BlogTag } from "@utils/globalTypes"

type Props = {
  allSanityBlogCategories: { node: BlogCategory }[]
  categoriesFilter: string
  setCategoriesFilter: (str: string) => void
  allSanityTags: { node: BlogTag }[]
  tagsFilter: string
  setTagsFilter: (str: string) => void
  allSortingOptions: any
  sortMethod: string
  setSortMethod: (str: string) => void
}

const FilterGroup = ({
  allSanityBlogCategories,
  categoriesFilter,
  setCategoriesFilter,
  allSanityTags,
  tagsFilter,
  setTagsFilter,
  allSortingOptions,
  sortMethod,
  setSortMethod,
}: Props) => {
  return (
    <Accordion defaultActiveKey="0" bsPrefix="filter-accordion">
      <h3 style={{ marginLeft: "0.25rem" }}>Filter</h3>
      <Card bsPrefix="filter-accordion__item">
        <IndividualFilterAccordion
          title="Select category"
          filterItems={allSanityBlogCategories.map(({ node }) => node)}
          checkedFilters={categoriesFilter?.split(",") || []}
          setCheckedFilters={setCategoriesFilter}
          isUniqueCheckboxFilter={false}
          eventKey="0"
        />

        <IndividualFilterAccordion
          title="Select Tag"
          filterItems={allSanityTags.map(({ node }) => node)}
          checkedFilters={tagsFilter?.split(",") || []}
          setCheckedFilters={setTagsFilter}
          isUniqueCheckboxFilter={false}
          eventKey="1"
        />

        <IndividualFilterAccordion
          title="Sort By"
          filterItems={allSortingOptions}
          checkedFilters={sortMethod?.split(",") || []}
          setCheckedFilters={setSortMethod}
          isUniqueCheckboxFilter={true}
          eventKey="2"
        />
      </Card>
    </Accordion>
  )
}

export default FilterGroup
