import React, { useState, useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import "./index.scss"
import { BlogCategory, BlogTag, SanityBlog, SanityBlogPost } from "@utils/globalTypes"
import BlogCard from "@components/BlogCard"
import PaginationNext from "@assets/svg/PaginationNext"
import BirchbrookIcon from "@assets/svg/Birchbrook_Icon_Navy.svg"
import CalendarFilter from "@components/Filters/Date"
import CategoriesFilter from "@components/Filters/Categories"
import ScrollUpButton from "@components/ScrollUpButton"
import FilterGroup from "@components/FilterGroup"
import { Row, Col, Pagination, Button, Image } from "react-bootstrap"
import SEO from "@components/SEO"
import useResizeWidth from "@hooks/useResizeWidth"
import PaginationPrev from "@assets/svg/PaginationPrev"
import moment from "moment"
import { BaseBlockContent } from "@utils/richText"
import UnalignedContentSection from "@components/ContentSection/UnalignedContentSection"
import TagsFilter from "@components/Filters/Tags"
import DateFilter from "@components/Filters/Date"

import { useQueryParam, StringParam } from "use-query-params"

type Props = {
  data: {
    sanityBlog: SanityBlog
    allSanityBlogCategory: { edges: { node: BlogCategory }[] }
    allSanityBlogPost: { edges: { node: SanityBlogPost }[] }
    allSanityBlogTags: { edges: { node: BlogTag }[] }
  }
  location: Location
}

const BlogPage = ({ data, location }: Props) => {
  const sanityBlog = data.sanityBlog

  const allSanityBlogCategory = data.allSanityBlogCategory?.edges
  const allSanityBlogPost = data.allSanityBlogPost?.edges
  const allSanityBlogTags = data.allSanityBlogTags?.edges
  const allSortingOptions = ["Featured", "New To Old", "Old To New"]
  const [pageItems, setPageItems] = useState(allSanityBlogPost) // Setting blogs displayed on the page

  // Filter checkboxes
  const [sortMethod = "New To Old", setSortMethod] = useQueryParam("sort", StringParam) // Sort method present in que query parameters
  const [categoriesFilter, setCategoriesFilter] = useQueryParam("categories", StringParam) // String representing the selected categories in the query parameters
  const [tagsFilter, setTagsFilter] = useQueryParam("tags", StringParam) // String representing the tags selected in the query parameters
  /**
   * Function for filtering by tags, categories, and sort the content
   * based on the query paremeters.
   */

  useEffect(() => {
    // Filter everytime the sortMethod, categories and tags change
    const tagsFilterArray = tagsFilter?.split(",") || []
    const categoriesFilterArray = categoriesFilter?.split(",") || []
    let filteredPageItems = allSanityBlogPost.filter((item) => {
      return (
        item?.node?.tags.some((tag) => tagsFilterArray?.includes(tag.tag)) || // This line filters the page items by tag
        categoriesFilterArray?.includes(item?.node?._rawCategory?.category) // This line lets items within the selected categories pass the filter
      )
    })

    let sortedPageItems =
      categoriesFilterArray?.length === 0 && tagsFilterArray?.length === 0 && sortMethod
        ? [...allSanityBlogPost]
        : filteredPageItems

    // Sort from Newest to oldest
    if (sortMethod === "New To Old") {
      sortedPageItems = sortedPageItems.sort((a, b) => {
        if (Date.parse(a?.node?.date) < Date.parse(b?.node?.date)) return 1
        else return -1
      })
    }

    //Sort from Oldest to Newest
    if (sortMethod === "Old To New") {
      sortedPageItems = sortedPageItems.sort((a, b) => {
        if (Date.parse(a?.node?.date) > Date.parse(b?.node?.date)) return 1
        else return -1
      })
    }

    // Sort by Featured blogs
    if (sortMethod === "Featured") {
      sortedPageItems = sortedPageItems.sort((a, b) => {
        if (a?.node?.featured) return -1
        else return 1
      })
    }

    // Set filters only if a box is checked
    if (categoriesFilter?.length > 0 || tagsFilter?.length > 0 || sortMethod) setPageItems(sortedPageItems)
    else setPageItems(allSanityBlogPost) // If no checkbox is active show all blogs
  }, [sortMethod, categoriesFilter, tagsFilter])

  const [page, setPage] = useState(0)
  const [calendar, setCalendar] = useState({
    date: moment(),
    startDate: null,
    endDate: null,
  })
  const [category, setCategory] = useState("")
  const [tag, setTag] = useState("")
  const [rawBody, setRawBody] = useState<any>(null)
  const pages = pageItems.length / 5
  const { width } = useResizeWidth()
  const { backgroundColor, title, _rawBody } = sanityBlog
  const metadata = {
    location: location.href,
    ...sanityBlog?.seo,
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search?.substring(1) || "")
    const hasCat = params.get("category") ? true : false
    const hasPage = params.get("page") ? true : false

    setPage(hasPage ? parseInt(params.get("page")) - 1 : 0)
    if (hasCat) {
      const cat = params.get("category")
      if (cat.includes(" amp; ")) {
        onCategoryChange(cat.replace(" amp; ", " & "))
      } else {
        onCategoryChange(params.get("category"))
      }
    } else {
      setCategory("")
    }
  }, [location])

  useEffect(() => {
    if (category) {
      allSanityBlogCategory.forEach((blogCategory) => {
        if (blogCategory.node.category === category) {
          setRawBody(blogCategory.node._rawBody)
        }
      })
    }
  }, [category])

  const onCategoryChange = (str: string) => {
    setCategory(str)
    if (str) {
      setPageItems(
        calendar?.startDate && calendar?.endDate
          ? allSanityBlogPost.filter(
              (item) =>
                item?.node?._rawCategory?.category === str &&
                moment(item?.node?.date).isBetween(calendar?.startDate, calendar?.endDate),
            )
          : allSanityBlogPost.filter((item) => item?.node?._rawCategory?.category === str),
      )
      setPage(0)
    } else {
      setPageItems(allSanityBlogPost)
      setPage(0)
      setCalendar({ ...calendar, startDate: null, endDate: null })
    }
  }

  const clearFilters = () => {
    setCategoriesFilter([].toString())
    setTagsFilter([].toString())
    setSortMethod([].toString())
  }

  const renderPagination = () => {
    const pagesArray = []
    for (let i = 0; i < pages; i++) {
      pagesArray.push(
        <li key={i} className={`${i === page ? "blog-active-page" : "blog-inactive-page"} page-item`}>
          <Link to={`?page=${i + 1}`} className="page-link">
            {i + 1}
          </Link>
        </li>,
      )
    }
    return pagesArray
  }

  return (
    <>
      <SEO {...metadata} />
      <section
        className="blog-wrapper content-padding-top parent-padding"
        style={{ backgroundColor: backgroundColor || "#DED9E5" }}
      >
        <div className="blog-content">
          {/* When a category is selected the title and content from that category will be displayed.
          This category and content come from "Blog Categories" inputs in sanity. If the category isn't
          available it means that there is no category selected so this will render the default content for
          the blog page which is fetched from the "Blog" document in sanity*/}

          {/*{category ? (
            <div className="blog-title">
              <h2>{category}</h2>
              {rawBody && <BaseBlockContent blocks={rawBody} />}
            </div>
          ) : (*/}
          <div className="blog-title">
            <h2>{title}</h2>
            {_rawBody && <BaseBlockContent blocks={_rawBody} />}
          </div>
          {/*)}*/}

          {width < 960 ? (
            <div>
              <Image src={BirchbrookIcon} className="blog-birchbrook-icon" fluid alt="birchbrook" />

              <FilterGroup
                allSanityBlogCategories={allSanityBlogCategory}
                categoriesFilter={categoriesFilter}
                setCategoriesFilter={setCategoriesFilter}
                allSanityTags={allSanityBlogTags}
                tagsFilter={tagsFilter}
                setTagsFilter={setTagsFilter}
                allSortingOptions={allSortingOptions}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />

              {/*            <div className="clear-btn-container">
                <Button variant="Link" title="Clear" onClick={clearFilters}>
                  Clear all Filters
                </Button>
              </div>*/}
            </div>
          ) : (
            <div className="blog-filter content-padding-top">
              <Image src={BirchbrookIcon} className="blog-birchbrook-icon" fluid alt="birchbrook" />

              <FilterGroup
                allSanityBlogCategories={allSanityBlogCategory}
                categoriesFilter={categoriesFilter}
                setCategoriesFilter={setCategoriesFilter}
                allSanityTags={allSanityBlogTags}
                tagsFilter={tagsFilter}
                setTagsFilter={setTagsFilter}
                allSortingOptions={allSortingOptions}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />

              {/* <div className="clear-btn-container">
                <Button variant="Link" title="Clear" onClick={clearFilters}>
                  Clear all Filters
                </Button>
              </div>*/}
            </div>
          )}

          <Row noGutters className="blog-articles">
            {pageItems.slice(page * 5, 5 * page + 5).map(({ node }, index) => (
              <Col key={index} className={`blog-card-container`}>
                <BlogCard post={node} />
              </Col>
            ))}

            {pageItems.length === 0 && (
              <Col className="no-blog-results">
                <h2 className="blog-title">Try changing the filter parameters to get results</h2>
              </Col>
            )}
          </Row>

          {pages > 1 && (
            <Pagination className="blog-pagination">
              <li className="pagination-button">
                {page > 0 ? (
                  <Link
                    to={`?page=${page}`}
                    className={`pagination-button btn btn-link ${page > 0 ? "blog-active-page" : "blog-inactive-page"}`}
                  >
                    <PaginationPrev />
                  </Link>
                ) : (
                  <Button variant="link" disabled title="Preview page" className="pagination-button blog-inactive-page">
                    <PaginationPrev />
                  </Button>
                )}
              </li>
              {renderPagination()}
              <li className="pagination-button">
                {page + 1 <= pages ? (
                  <Link
                    to={`?page=${page + 2}`}
                    className={`btn btn-link ${page > 0 ? "blog-active-page" : "blog-inactive-page"}`}
                  >
                    <PaginationNext />
                  </Link>
                ) : (
                  <Button variant="link" disabled title="Next page" className="blog-inactive-page">
                    <PaginationNext />
                  </Button>
                )}
              </li>
            </Pagination>
          )}
        </div>
        {width > 640 ? (
          <ScrollUpButton type="dark" />
        ) : (
          <Row
            className="blog-backTop-container py-4"
            style={{
              backgroundColor: `transparent`,
            }}
          >
            {" "}
            <ScrollUpButton
              type="dark"
              style={{
                position: "relative",
                opacity: 1,
                right: "initial",
                bottom: "initial",
              }}
            />{" "}
          </Row>
        )}
      </section>
    </>
  )
}

export default BlogPage

export const query = graphql`
  query SanityBlog($slug: String) {
    sanityBlog(slug: { current: { eq: $slug } }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      _rawBody(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
    }
    allSanityBlogCategory {
      edges {
        node {
          category
          _rawBody(resolveReferences: { maxDepth: 10 })
        }
      }
    }

    allSanityBlogTags {
      edges {
        node {
          tag
        }
      }
    }

    allSanityBlogPost {
      edges {
        node {
          title
          date
          author
          slug {
            current
          }
          featured
          _rawCategory(resolveReferences: { maxDepth: 10 })
          _rawArticle(resolveReferences: { maxDepth: 10 })
          image {
            asset {
              fluid(maxWidth: 10000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          tags {
            tag
          }
        }
      }
    }
  }
`
