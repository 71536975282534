import React from "react"
import "./index.scss"
import { SanityBlogPost } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import { Card } from "react-bootstrap"
import Img from "gatsby-image"
import { Link } from "gatsby"

type Props = {
  post: SanityBlogPost
}

const formatDate = (dateString: string) => {
  const date = dateString ? new Date(dateString) : new Date()
  const day =
    date.getDate() + 1 >= 10 ? date.getDate() + 1 : `0${date.getDate() + 1}`
  const month =
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  const year = date.getFullYear().toString().substring(2)
  return `${month}/${day}/${year}`
}

const BlogCard = ({ post }: Props) => {
  
  
  const date = formatDate(post?.date)
  const textBody = post._rawArticle
    .map((item) => item?.children?.map((child) => child?.text || ""))
    .join("")

    
  return (
    <Card style={{ width: "100%" }}>
      <Link
        to={`/viewpoints${post?.slug?.current}`}
        className="blog-card-image"
      >
        <Img
          fluid={post?.image?.asset?.fluid}
          alt={post?.image?.alt || post.title}
          imgStyle={{ objectFit: "cover" }}
        />
      </Link>

      <Card.Body className="blog-card-body">
        <Link to={`/viewpoints${post?.slug?.current}`}>
          <h4>{post.title}</h4>
        </Link>
        <Card.Text>
          {textBody.substring(0, 120)}...{" "}
          <Link to={`/viewpoints${post?.slug?.current}`}>Read more</Link>
        </Card.Text>
        <h6>
          {date} | {post?.author} | {post?._rawCategory?.category || " "}{" "}
        </h6>
      </Card.Body>
    </Card>
  )
}

export default BlogCard
