import React from "react"

const PaginationPrev = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.145"
    height="19.459"
    viewBox="0 0 11.145 19.459"
  >
    <g
      id="Group_499"
      data-name="Group 499"
      transform="translate(10.438 18.752) rotate(180)"
    >
      <g
        id="Group_46"
        data-name="Group 46"
        transform="translate(0 18.045) rotate(-90)"
      >
        <path
          id="Path_48"
          data-name="Path 48"
          d="M0,0,9.023,9.024,18.045,0"
          fill="none"
          stroke="#090d31"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default PaginationPrev
