import React, { useState } from "react"
import "../index.scss"
import CheckedFilterIcon from "@assets/svg/CheckedFilterIcon"
import UncheckedFilterIcon from "@assets/svg/UncheckedFilterIcon"
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form"
import Accordion from "react-bootstrap/Accordion"
import AccordionArrowDown from "@assets/svg/AccordionCarrot"
import AccordionArrowUp from "@assets/svg/AccordionArrowUp"
import AccordionCarrot from "@assets/svg/AccordionCarrot"
import { useAccordionButton } from "react-bootstrap/AccordionButton"

type Props = {
  title: string
  filterItems: any
  checkedFilters: string[]
  setCheckedFilters: (str: string) => void
  eventKey: string // Unique key used to control this item's collapse / expand.
  isUniqueCheckboxFilter: boolean
}

const IndividualFilterAccordion = ({
  filterItems,
  title,
  checkedFilters,
  setCheckedFilters,
  eventKey,
  isUniqueCheckboxFilter,
}: Props) => {
  const [selectedAccordion, setSelectedAccordion] = useState("0") // Variable that allows accordion icon to change when extended

  /**
   * When any filter changes its state the slug will be added to an array
   * of checked filters, if it is already checked then it will remove it from the array
   */
  const handleCheckChange = ({ target }) => {
    // Check is the filter can only have one active box and remove de check if it's already selected
    if (isUniqueCheckboxFilter) {
      if (checkedFilters.includes(target.name)) return setCheckedFilters([].toString())
      else return setCheckedFilters([target?.name].toString())
    }

    if (target.checked) {
      setCheckedFilters([...checkedFilters, target?.name].toString())
    } else {
      const params = checkedFilters?.filter((item) => item !== target?.name)
      setCheckedFilters(params.length > 0 ? params.toString() : null)
    }
  }

  /**
   * Verifies if the given item is inside the checked filters array
   * @param {string} item slug corresponding to the checkbox
   * @returns {boolean} A value indicating that the slug is in the checked filters array
   */
  const isChecked = (item: string) => checkedFilters.includes(item)

  /**
   * Toggle functionality for the use of accordion icons
   */
  const CustomToggle = ({ children, eventKey }) => {
    const thisAccordionExpanded = selectedAccordion === eventKey
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      eventKey === selectedAccordion ? setSelectedAccordion("-1") : setSelectedAccordion(eventKey),
    )

    return (
      <button
        type="button"
        style={{ background: "#ded9e5", display: "flex", flexDirection: "row", alignItems: "center" }}
        onClick={decoratedOnClick}
        className={`flex justify-between w-full items-center accordion-button ${
          !thisAccordionExpanded ? "unselected-accordion" : ""
        }`}
      >
        {children} {thisAccordionExpanded ? <AccordionCarrot /> : <AccordionCarrot />}
      </button>
    )
  }

  return (
    <>
      <Card.Header bsPrefix="filter-accordion__header">
        <CustomToggle eventKey={eventKey}>{<h5>{title}</h5>} </CustomToggle>
      </Card.Header>

      <Accordion.Collapse eventKey={eventKey} bsPrefix="filter-accordion__body">
        <Form>
          <Form.Group controlId="filterCheckboxes">
            {filterItems?.map((item, index) => (
              <Form.Check
                type="checkbox"
                id={`${index}-${item?.category || item?.tag || item}`}
                key={index}
                inline
                className="filter-accordion__checkbox"
              >
                <Form.Check.Input
                  type="checkbox"
                  id={`${index}-${item?.category || item?.tag || item}`}
                  name={item?.category || item?.tag || item}
                  onChange={handleCheckChange}
                  checked={isChecked(item?.category || item?.tag || item)}
                  className="check-input"
                />
                <Form.Check.Label className="flex check-label items-center accordion-checkbox">
                  {isChecked(item?.category || item?.tag || item) ? <CheckedFilterIcon /> : <UncheckedFilterIcon />}
                  <span>{item?.category || item?.tag || item}</span>
                </Form.Check.Label>
              </Form.Check>
            ))}
          </Form.Group>
        </Form>
      </Accordion.Collapse>
    </>
  )
}

export default IndividualFilterAccordion
