import React from "react"

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 5.2916666 5.2916667"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layer1">
      <rect
        className="cls-1"
        x="0.075231872"
        y="0.075231872"
        width="5.1412029"
        height="5.1412029"
        id="rect154"
        stroke="#0a0e2f"
        strokeWidth="0.529167"
        strokeMiterlimit="10"
        fill="#ddd7e4"
      />
    </g>
  </svg>
)
